<template>
  <div>
    <div class="resume">
      <!-- 头部 -->
      <div class="resumeA">
        <div class="resumeAone">
          <img src="../../assets/qiuzhizhaopin.png" alt />
        </div>
        <div class="resumeAtwo">
          <div style="font-size: 14px">
            <span style="font-size: 20px; font-weight: 700; padding: 0px 5px"
              >{{resumedataA.Name}}</span
            >/
            <span style="padding: 0px 5px">{{resumedataA.SexDesc}}</span>
            <span
              v-if="resumedataA.WorkStateDesc"
              style="
                border: 1px solid #1787fb;
                padding: 3px;
                color: #1787fb;
                padding: 3px 5px;
                font-size:12pxs
              "
              >{{resumedataA.WorkStateDesc}}</span
            >
            <!-- <router-link :to="'/list/Add/' + 1">
              <van-icon
                name="edit"
                style="
                  margin-left: 20px;
                  border: 1px solid;
                  border-radius: 50px;
                  color: #0188fb;
                  padding: 2px;
                "
              />
            </router-link> -->
          </div>
          <div style="margin: 8px">21岁 | 工作{{resumedataA.WorkYear}}年 | {{resumedataA.PoliticsName}}</div>
          <div style="margin: 8px">{{resumedataA.LiveCityName}}</div>
        </div>
        <div class="resumeAthree">
          <div>
            <van-icon
              name="phone-circle"
              style="padding-right: 15px"
            />{{resumedataA.Mobile | mobileFilter}}
          </div>
          <div>
            <van-icon
              name="envelop-o"
              style="padding-right: 15px"
            />{{resumedataA.Email}}
          </div>
          <div></div>
        </div>
      </div>
      <!-- 求职意向 -->
      <div class="resumeB">
        <div class="resumeBone">
          <span>求职意向</span>
          <!-- <span style="color: #0188fb">
            <router-link :to="'/list/Add/' + 2">
              <van-icon name="add-o" />
            </router-link>
          </span> -->
        </div>
        <div class="resumeBtwo" v-if="resumeBshow">
          <van-form v-for="item in resumedataB" :key="item">
          <div>
            {{item.AbiName}}
            <!-- <span
              @click="deleteB(item.ReJWId)"
              style="color: red; position: relative; top: 3px"
            >
              <van-icon name="close" />
            </span> -->
          </div>
          <div>{{item.MinSalary}}-{{item.MaxSalary}}元/月 | {{item.WorkCityName}} </div>
          <div>{{item.IndName}}</div>
          </van-form>
        </div>
        <div v-if="blankshowB">
          <div class="blankshow">
            还没有添加相关经历~
          </div>
        </div>
      </div>
      <!-- 工作经历 -->
      <div class="resumeC">
        <div class="resumeBone">
          <span>工作经历</span>
          <!-- <span style="color: #0188fb">
            <router-link :to="'/list/Add/' + 3">
              <van-icon name="add-o" />
            </router-link>
          </span> -->
        </div>
        <div class="resumeCtwo" v-if="resumeCshow">
          <van-form v-for="item in resumedataC" :key="item">
          <div>
            {{item.Company}}
            <!-- <span
              @click="deleteC(item.RWId)"
              style="color: red; position: relative; top: 3px"
            >
              <van-icon name="close" />
            </span> -->
          </div>
          <div>
            <span>{{item.Position}} | {{item.Salary}}元/月</span>
            <span style="padding-right: 15px">{{item.InDate}}-{{item.LeaveDate}}</span>
          </div>
          <div>
            {{item.Describe}}
          </div>
          </van-form>
        </div>
        <div v-if="blankshowC">
          <div class="blankshow">
            还没有添加相关经历~
          </div>
        </div>
      </div>
      <!-- 项目经历 -->
      <div class="resumeC">
        <div class="resumeBone">
          <span>项目经历</span>
          <!-- <span style="color: #0188fb">
            <router-link :to="'/list/Add/' + 5">
              <van-icon name="add-o" />
            </router-link>
          </span> -->
        </div>
        <div class="resumeCtwo" v-if="resumeDshow">
          <van-form v-for="item in resumedataE" :key="item">
          <div>
            {{item.Title}}
            <!-- <span
              @click="deleteD(item.RePId)"
              style="color: red; position: relative; top: 3px"
            >
              <van-icon name="close" />
            </span> -->
          </div>
          <div>
            <span style="padding-right: 15px">{{item.StartTime}} 至 {{item.EndTime}}</span>
          </div>
          <div>
            项目职责：{{item.ProjectDuty}}
          </div>
          <div>项目业绩：{{item.Achievement}}</div>
          </van-form>
        </div>
        <div v-if="blankshowE">
          <div class="blankshow">
            还没有添加相关经历~
          </div>
        </div>
      </div>
      <!-- 教育经历 -->
      <div class="resumeB">
        <div class="resumeBone">
          <span>教育经历</span>
          <!-- <span style="color: #0188fb">
            <router-link :to="'/list/Add/' + 4">
              <van-icon name="add-o" />
            </router-link>
          </span> -->
        </div>
        <div class="resumeBtwo" v-for="item in resumedataD" :key="item.index" >
          <div v-if="resumeEshow">
            <div>
            {{item.School}}
            <!-- <span
              @click="deleteE(item.ReEId)"
              style="color: red; position: relative; top: 3px"
            >
              <van-icon name="close" />
            </span> -->
          </div>
          <div>{{item.InDate}} 至 {{item.EndTime}}</div>
          <div>{{item.EducationName}} | {{item.Profession}}</div>
          </div>
        </div>
        <div v-if="blankshowD">
          <div class="blankshow">
            还没有添加相关经历~
          </div>
        </div>
      </div>
      <!-- 培训经历 -->
      <div class="resumeB">
        <div class="resumeBone">
          <span>培训经历</span>
          <!-- <span style="color: #0188fb">
            <router-link :to="'/list/Add/' + 6">
              <van-icon name="add-o" />
            </router-link>
          </span> -->
        </div>
        <div class="resumeBtwo" v-if="resumeFshow">
          <van-form v-for="item in resumedataF" :key="item">
          <div>
            {{item.School}}
            <!-- <span
              @click="deleteF(item.ReTId)"
              style="color: red; position: relative; top: 3px"
            >
              <van-icon name="close" />
            </span> -->
          </div>
          <div>{{item.StartTime}}-{{item.EndTime}}</div>
          <div>{{item.Course}}</div>
          </van-form>
        </div>
        <div v-if="blankshowF">
          <div class="blankshow">
            还没有添加相关经历~
          </div>
        </div>
      </div>
      <!-- 语言能力 -->
      <div class="resumeB">
        <div class="resumeBone">
          <span>语言能力</span>
          <!-- <span style="color: #0188fb">
            <router-link :to="'/list/Add/' + 7">
              <van-icon name="add-o" />
            </router-link>
          </span> -->
        </div>
        <div class="resumeBtwo" v-if="resumeGshow">
          <van-form v-for="item in resumedataG" :key="item">
          <div>
            {{item.LanguageName}}
            <!-- <span
              @click="deleteG(item.ReLId)"
              style="color: red; position: relative; top: 3px"
            >
              <van-icon name="close" />
            </span> -->
          </div>
          <div>听说能力:{{item.ReadAbility}} | 读写能力:{{item.WriteAbility}}</div>
          </van-form>
        </div>
        <div v-if="blankshowG">
          <div class="blankshow">
            还没有添加相关经历~
          </div>
        </div>
      </div>
      <!-- 专业技能 -->
      <div class="resumeD">
        <div class="resumeBone">
          <span>专业技能</span>
          <!-- <span style="color: #0188fb">
            <router-link :to="'/list/Add/' + 8">
              <van-icon name="add-o" />
            </router-link>
          </span> -->
        </div>
        <div class="resumeDtwo" v-if="resumeHshow">
          <van-form v-for="item in resumedataH" :key="item">
          <span>
            {{item.Name}}
            <!-- <span
              @click="deleteH(item.ReSId)"
              style="color: red; position: relative; top: 3px"
            >
              <van-icon name="close" />
            </span> -->
          </span>
          <span class="shijian">使用时长{{item.Duration}}月</span>
          </van-form>
        </div>
        <div v-if="blankshowH">
          <div class="blankshow">
            还没有添加相关经历~
          </div>
        </div>
      </div>
      <!-- 获得证书 -->
      <div class="resumeD">
        <div class="resumeBone">
          <span>证书</span>
          <!-- <span style="color: #0188fb">
            <router-link :to="'/list/Add/' + 9">
              <van-icon name="add-o" />
            </router-link>
          </span> -->
        </div>
        <div class="resumeDtwo" v-if="resumeIshow"> 
          <van-form v-for="item in resumedataI" :key="item">
          <span>
            {{item.Name}}
            <!-- <span
              @click="deleteI(item.ReCId)"
              style="color: red; position: relative; top: 3px"
            >
              <van-icon name="close" />
            </span> -->
          </span>
          <span class="shijian">获取时间:{{item.GetDate}}</span>
          </van-form>
        </div>
        <div v-if="blankshowI">
          <div class="blankshow">
            还没有添加相关经历~
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { Dialog } from "vant";
Vue.use(Dialog);
import {
  RE_GetResume,
  RE_GetReJobwantList,
  RE_GetReWorkList,
  RE_GetReEducationList,
  RE_GetReProjectList,
  RE_GetReTrainList,
  RE_GetReLanguageList,
  RE_GetReSkillList,
  RE_GetReCertificateList,
} from "@/api/REplat";
import { getaccToken, setaccToken } from "@/utils/auth";
export default {
  data() {
    return {
      // 页面渲染数据
      resumedataA: [],
      resumedataB: [],
      resumedataC: [],
      resumedataD: [],
      resumedataE: [],
      resumedataF: [],
      resumedataG: [],
      resumedataH: [],
      resumedataI: [],
      // 经历隐藏与显示
      resumeAshow: true,
      resumeBshow: true,
      resumeCshow: true,
      resumeDshow: true,
      resumeEshow: true,
      resumeFshow: true,
      resumeGshow: true,
      resumeHshow: true,
      resumeIshow: true,
      // 空白页显示与隐藏
      blankshowB:false,
      blankshowC:false,
      blankshowD:false,
      blankshowE:false,
      blankshowF:false,
      blankshowG:false,
      blankshowH:false,
      blankshowI:false,
      resumeget: {
        accToken:getaccToken(), //token
        ReId: "",
      },
    };
  },
  filters:{
    mobileFilter(val){
      let reg = /^(.{3}).*(.{4})$/
      return val.replace(reg,'$1****$2')
    }
  },
  created() {
    console.log(this.$route);
    var token = getaccToken();
    if (token == null) {
      this.$router.push({
        name: "recruitment",
      });
    }
    this.GetMyResumes();
  },
  methods: {
    // 获取简历详情内容
    GetMyResumes: function () {
      // 获取简历详情
      RE_GetResume({ accToken: getaccToken(),reId:this.$route.query.ReId }).then((res) => {
        if (res.data.code == 0) {
          this.resumeget.ReId = this.$route.query.ReId;
          this.resumedataA = res.data.data;
          this.getresume();
        } else {
          // this.$message({ message: "操作失败, " + res.msg, type: "error" });
        }
      });
    },
    getresume: function () {
      // 获取求职意向详情
      RE_GetReJobwantList(this.resumeget).then((res) => {
        console.log(res);
        if (res.data.data.length == 0) {
          this.blankshowB = true;
          this.resumeBshow = false;
        } else {
          this.resumedataB = res.data.data;
        }
      });
      // 获取工作经历信息
      RE_GetReWorkList(this.resumeget).then((res) => {
        if (res.data.data.length == 0) {
          this.blankshowC = true;
          this.resumeCshow = false;
        } else {
          this.resumedataC = res.data.data;
        }
      });
      // 获取教育经历信息
      RE_GetReEducationList(this.resumeget).then((res) => {
        if (res.data.data.length == 0) {
          this.blankshowD = true;
          this.resumeDshow = false;
        } else {
          this.resumedataD = res.data.data;
          this.resumeEshow = true;
        }
      });
      // 获取项目经历信息
      RE_GetReProjectList(this.resumeget).then((res) => {
        if (res.data.data.length == 0) {
          this.blankshowE = true;
          this.resumeEshow = false;
        } else {
          this.resumedataE = res.data.data;
        }
      });
      // 获取培训经历信息
      RE_GetReTrainList(this.resumeget).then((res) => {
        if (res.data.data.length == 0) {
          this.blankshowF = true;
          this.resumeFshow = false;
        } else {
          this.resumedataF = res.data.data;
        }
      });
      // 获取语言能力信息
      RE_GetReLanguageList(this.resumeget).then((res) => {
        if (res.data.data.length == 0) {
          this.blankshowG = true;
          this.resumeGshow = false;
        } else {
          var resumedataG = res.data.data;
          for (let index = 0; index < resumedataG.length; index++) {
            if(resumedataG[index].ReadAbility == 1){
              resumedataG[index].ReadAbility = '一般';
            }else if(resumedataG[index].ReadAbility == 2){
              resumedataG[index].ReadAbility = '良好';
            }else if(resumedataG[index].ReadAbility == 3){
              resumedataG[index].ReadAbility = '熟练';
            }else if(resumedataG[index].ReadAbility == 4){
              resumedataG[index].ReadAbility = '精通';
            }
            if(resumedataG[index].WriteAbility == 1){
              resumedataG[index].WriteAbility = '一般';
            }else if(resumedataG[index].WriteAbility == 2){
              resumedataG[index].WriteAbility = '良好';
            }else if(resumedataG[index].WriteAbility == 3){
              resumedataG[index].WriteAbility = '熟练';
            }else if(resumedataG[index].WriteAbility == 4){
              resumedataG[index].WriteAbility = '精通';
            }
          }
          this.resumedataG = resumedataG;
        }
      });
      // 获取专业技能详情
      RE_GetReSkillList(this.resumeget).then((res) => {
        if (res.data.data.length == 0) {
          this.blankshowH = true;
          this.resumeHshow = false;
        } else {
          this.resumedataH = res.data.data;
        }
      });
      // 获取证书信息
      RE_GetReCertificateList(this.resumeget).then((res) => {
        if (res.data.data.length == 0) {
          this.blankshowI = true;
          this.resumeIshow = false;
        } else {
          this.resumedataI = res.data.data;
        }
      });
    },
  },
};
</script>
<style>
.resume {
  margin-top: 20px;
  margin-bottom: 50px;
}
.resumeA {
  position: relative;
}
.resumeAone {
  display: inline-block;
  width: 23%;
  margin-left: 15px;
}
.resumeAone img {
  width: 100%;
}
.resumeAtwo {
  display: inline-block;
  position: absolute;
  top: 10px;
  margin-left: 20px;
}
.resumeAthree {
  margin-left: 30px;
  margin-top: 50px;
}
.resumeAthree div {
  margin: 8px;
}
.resumeB {
  margin-left: 15px;
  margin-top: 15px;
}
.resumeBone {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.resumeBone span:nth-child(1) {
  font-size: 18px;
}
.resumeBone span:nth-child(2) {
  padding-right: 15px;
}
.resumeBtwo {
  margin-top: 10px;
}
.resumeBtwo div {
  padding: 3px;
}
.resumeBtwo div:nth-child(2) {
  font-size: 14px;
  color: #333;
}
.resumeBtwo div:nth-child(3) {
  font-size: 13px;
  color: #666;
}
.resumeC {
  margin-left: 15px;
  margin-top: 15px;
}
.resumeCtwo {
  padding-right: 15px;
  padding-top: 15px;
}
.resumeCtwo div:nth-child(2) {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px 0px;
}
.resumeCtwo div:nth-child(2) span {
  font-size: 14px;
  color: #333;
}
.resumeCtwo div:nth-child(3) {
  margin-bottom: 5px;
  font-size: 14px;
  color: #666;
}
.resumeCtwo div:nth-child(4) {
  margin-bottom: 5px;
  font-size: 14px;
  color: #666;
}
.resumeD {
  margin-left: 15px;
  margin-top: 15px;
}
.resumeDtwo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 8px 0px;
}
.blankshow {
  font-size: 15px;
  color: #333;
  margin: 8px;
  text-align: center;
}
.shijian {
  display: block;
    font-size: 14px;
    margin-top: 5px;
    color: #666;
}
</style>